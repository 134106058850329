import type { SchoolOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";

import { eyeOutline } from "@assets/Icons";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";

import type {
	BackgroundColorType,
	BorderColorType,
	TextColorType,
} from "@/types/colorsType";
import type { EmailTrackingType } from "@/types/offerTypes";
import { querySelectionEmailTrackingAndConsultedEntities } from "@containers/school/ProfileSharing/ProfileSharingAPI";
import { SelectionModal } from "@containers/school/ProfileSharing/ui/SelectionModal";

type ShipmentTrackingType = {
	date: string | null;
	email: string | null;
	status: {
		label: string;
		bgColor?: BackgroundColorType;
		textColor?: TextColorType | null;
		borderColor?: BorderColorType | null;
	};
};

export function ShipmentTrackingCard() {
	const { offerToEdit } = useAppSelector((state) => state.offers);
	const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
	const [selectionEmailTracking, setSelectionEmailTracking] = useState<
		EmailTrackingType[]
	>([]);
	const [selectionConsultedEntities, setSelectionConsultedEntities] = useState<
		{ id: string }[]
	>([]);

	const updateSelectionEmailTrackingAndConsultedEntities = () => {
		if (!offerToEdit?.selection) return;
		querySelectionEmailTrackingAndConsultedEntities(
			offerToEdit.selection.id,
		).then((response: any) => {
			setSelectionEmailTracking(response.emailsTracking);
			setSelectionConsultedEntities(response.consultedEntities);
		});
	};
	useEffect(() => {
		updateSelectionEmailTrackingAndConsultedEntities();
	}, [offerToEdit]);

	return (
		<aside className="flex w-full flex-col items-start justify-center gap-sm rounded-lg border border-primary-150 bg-white px-md py-sm">
			<p className="uppercase text-primary-300">
				Suivi des profils envoyés à l’entreprise
			</p>
			<div className="flex justify-start items-center gap-xxsm">
				<p className="text-base font-semibold text-primary-700P">
					{offerToEdit?.sentToCompanyCount || 0} profils envoyé(s) -{" "}
					{selectionConsultedEntities.length || 0} consulté(s)
				</p>

				{offerToEdit?.selection && (
					<ButtonTertiary
						size="small"
						icon={eyeOutline}
						className="text-primary-700P"
						id="tooltip-shipment-tracking-element"
						tooltip="Voir le partage de profils"
						onClick={() => setIsSelectionModalOpen(true)}
					/>
				)}
				{offerToEdit?.selection && (
					<SelectionModal
						selectionId={offerToEdit?.selection?.id || ""}
						show={isSelectionModalOpen}
						onClose={() => setIsSelectionModalOpen(false)}
						user={{} as any}
						onResend={updateSelectionEmailTrackingAndConsultedEntities}
					/>
				)}
			</div>
			<div className="flex flex-col gap-sm">
				{selectionEmailTracking.length > 0 &&
					selectionEmailTracking.map((emailTracking: EmailTrackingType) => (
						<RenderShipmentTrackingElement
							shipment={{
								date: emailTracking.sentAt || null,
								email: emailTracking.emailAddress,
								status: resolveTrackingStatus(emailTracking.status),
							}}
							offerData={offerToEdit as SchoolOfferType}
						/>
					))}
			</div>
		</aside>
	);
}

export const resolveTrackingStatus = (status: number) => {
	let trackingStatus: {
		label: string;
		bgColor: BackgroundColorType;
		borderColor: BorderColorType;
		textColor?: TextColorType;
	} = {
		label: "Non reçu",
		bgColor: "bg-error-light",
		borderColor: "border-error-dark",
	};
	switch (status) {
		case 0:
		case 1:
			trackingStatus = {
				label: "En cours d’envoi",
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
			};
			break;
		case 2:
		case 3:
		case 4:
			trackingStatus = {
				label: "Non reçu",
				bgColor: "bg-error-light",
				borderColor: "border-error-dark",
			};
			break;

		case 5:
			trackingStatus = {
				label: "Reçu",
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
			};
			break;

		case 6:
			trackingStatus = {
				label: "Ouvert",
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
			};
			break;

		case 7:
			trackingStatus = {
				label: "Cliqué",
				bgColor: "bg-success-light",
				borderColor: "border-success-dark",
			};
			break;

		default:
	}
	return trackingStatus;
};

const RenderShipmentTrackingElement = ({
	shipment,
	offerData,
}: {
	shipment: ShipmentTrackingType;
	offerData: SchoolOfferType;
}) => {
	if (!offerData?.selection || !shipment.date) return null;
	return (
		<div className="flex items-center justify-start gap-xxsm">
			<p className="text-base font-normal text-primary-700P">
				{shipment.date
					? `Mail du ${new Date(shipment.date).toLocaleDateString(
							"fr-FR",
						)} à ${new Date(shipment.date).toLocaleTimeString("fr-FR")}`
					: "Mail"}{" "}
				{shipment.email ? `envoyé à ${shipment.email}` : ""}
			</p>
			<Badge
				label={shipment?.status?.label || ""}
				size="medium"
				bgColor={shipment?.status?.bgColor || "bg-white"}
				textColor={shipment?.status?.textColor || "text-primary-500"}
				borderColor={shipment?.status?.borderColor || "border-primary-500"}
				hasBorder
			/>
		</div>
	);
};
