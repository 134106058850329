import { setActiveOffer } from "@/containers/company/core/store/company.slice";
import type { EntOffersType } from "@/types/EntCompanyTypes";
import type { EntOfferStatusSelectionOption } from "@/types/offerTypes";
import {
	arrowIosForwardOutline,
	briefcaseOutline,
	pinOutline,
} from "@assets/Icons";
import { ENT_COMPANY_OFFER_STATUS } from "@constants/Company";
import { CONTRACT_TYPE_OPTIONS } from "@constants/Offers";
import { Badge } from "@designSystem/atoms/Badge";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { Icon } from "@iconify/react";
import { convertDateFromNow } from "@tools/Dates";
import { selectionHasNewUsers } from "@tools/Selections";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

export function OfferSmallCard({
	offer,
	isActive,
}: {
	offer: EntOffersType;
	isActive: boolean;
}) {
	const [showTooltip, setShowTooltip] = useState<boolean>(false);

	const jobRef = useRef<HTMLParagraphElement>(null);
	const dispatchEvent = useDispatch();

	const getContract = (contractType: string) => {
		const label =
			CONTRACT_TYPE_OPTIONS.find((option) => {
				return option.value === contractType;
			}) ||
			CONTRACT_TYPE_OPTIONS.find((option) => {
				return option.default;
			});
		if (label) return label.label;
		return "erreur label";
	};
	useEffect(() => {
		if (jobRef?.current) {
			const jobName = jobRef.current;
			if (jobName.offsetWidth < jobName.scrollWidth) {
				setShowTooltip(true);
			}
		}
	}, []);

	let status: EntOfferStatusSelectionOption | undefined;
	if (selectionHasNewUsers(offer.spsSelection)) {
		status = ENT_COMPANY_OFFER_STATUS.find((e) => e.value === "new");
	} else if (
		location.pathname.includes("partners") &&
		offer.status === "pending"
	) {
		status = ENT_COMPANY_OFFER_STATUS.find((e) => e.value === "toTreatSchool");
	} else
		status =
			ENT_COMPANY_OFFER_STATUS.find((e) => e.value === offer.status) ||
			ENT_COMPANY_OFFER_STATUS.find((e) => e.default);

	return (
		<div
			className={`relative flex cursor-pointer rounded-md ${
				isActive
					? "border border-solid border-primary-500 bg-primary-50 p-2 shadow"
					: "p-2 shadow"
			}`}
			onClick={(e: any) => {
				e.stopPropagation();
				dispatchEvent(setActiveOffer(offer));
			}}
		>
			<div className="w-11/12">
				<div className="flex items-center justify-between">
					<span className="truncate text-xs text-primary-300">
						{`Déposé il y a ${convertDateFromNow(
							offer.createdAt || new Date(),
						)}`}
					</span>
					<Badge
						label={status?.label as string}
						size="medium"
						hasBorder
						bgColor={status?.bgColor}
					/>
				</div>
				<div className="my-2 flex">
					<div className="w-full">
						{showTooltip && (
							<TooltipContainer
								anchorId={`#offer-${offer.job.replaceAll(" ", "-")}`}
								place="top"
								float={false}
							>
								{offer.job}
							</TooltipContainer>
						)}
						<span id={`offer-${offer.job.replaceAll(" ", "-")}`}>
							<p
								className="wg-no-translate w-9/12 truncate font-bold"
								ref={jobRef}
							>
								{offer.job}
							</p>
						</span>
						<div className="flex gap-xsm">
							<span className="flex items-center gap-xxsm">
								<Icon icon={briefcaseOutline} />
								{getContract(offer.contract)}
							</span>
							<span className="flex items-center gap-xxsm">
								<Icon icon={pinOutline} />
								{offer.location}
							</span>
						</div>
					</div>
				</div>
				{isActive ? (
					<Icon
						width="24"
						height="24"
						icon={arrowIosForwardOutline}
						className="absolute right-0 top-1/2 ml-auto -translate-y-1/2 transform text-primary-500"
					/>
				) : null}
			</div>
		</div>
	);
}
