import React from "react";

import { Modal } from "@designSystem/organisms/modal/Modal";
type CloseOfferModalType = {
	show: boolean;
	onClose: () => void;
};
export function SwitchSchoolModal({ show, onClose }: CloseOfferModalType) {
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Confirmer la clôture"
			body={<p>Test</p>}
		/>
	);
}
