import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import { checkEmail, checkPhone } from "@tools/Checkers";

import type { CompanySliceType } from "@/containers/company/core/store/company.slice";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Spinner } from "@designSystem/atoms/Spinner";
import { Switch } from "@designSystem/atoms/Switch";
import { Input } from "@designSystem/molecules/Input";
import { InputPhoneNumber } from "@designSystem/molecules/PhoneInput";
import { createEntEmployee } from "../RegisterCompanyAPI";
import { getCompanySubdomain } from "@/tools/Auth";

type ProfileFormType = {
	setHasErrors: (e: boolean) => void;
	hasErrors: boolean;
	goNext: () => void;
	setLoading: (e: boolean) => void;
	setEmail: (e: string) => void;
	loading: boolean;
};
export function ProfileForm({
	setHasErrors,
	loading,
	setLoading,
	goNext,
	hasErrors,
	setEmail,
}: ProfileFormType) {
	const [accountExists, setAccountExists] = useState(false);
	const [defaultEmail, setDefaultEmail] = useState("");
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const { school } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);

	useEffect(() => {
		if (searchParams.get("email")) {
			setDefaultEmail(searchParams.get("email") as string);
		}
	}, []);
	type ValuesType = {
		email?: string;
		phone?: string;
		firstname?: string;
		lastname?: string;
		password?: string;
		cgu?: boolean;
		rgpd?: boolean;
	};
	type ErrorsType = {
		email?: string;
		phone?: string;
		firstname?: string;
		lastname?: string;
		password?: string;
		cgu?: string;
		rgpd?: string;
	};
	const validate = (values: ValuesType) => {
		const errors: ErrorsType = {};
		if (!values.email || !checkEmail(values.email)) {
			errors.email = "Veuillez entrer une adresse email valide";
		}
		if (!values.firstname) {
			errors.firstname = "Veuillez entrer un prénom";
		}
		if (!values.lastname) {
			errors.lastname = "Veuillez entrer un nom";
		}
		if (!values.email || !checkEmail(values.email)) {
			errors.email = "Veuillez entrer une adresse email valide";
		}
		if (!values.phone || !checkPhone(values.phone)) {
			errors.phone = "Veuillez entrer un numéro de téléphone valide";
		}
		if (!values.cgu || !values.rgpd) {
			errors.cgu = "Veuillez accepter les CGU et le RGPD";
		}

		setHasErrors(Object.values(errors).length > 0);
		return errors;
	};
	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				email: defaultEmail,
				phone: "",
				firstname: "",
				lastname: "",
				password: "",
				cgu: false,
				rgpd: false,
				originSchoolId: getCompanySubdomain(),
			}}
			onSubmit={async (values) => {
				if (hasErrors) return;
				setAccountExists(false);
				setLoading(true);
				try {
					await createEntEmployee(values);
					setEmail(values.email);
					goNext();
				} catch (e) {
					if (e.code === 409) {
						setAccountExists(true);
					} else {
						Error("Une erreur est survenue lors de l'inscription");
					}
				} finally {
					setLoading(false);
				}
			}}
			validate={(values) => {
				return validate(values);
			}}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
				<form
					id="form-login"
					className="mx-auto mb-lg w-full"
					onSubmit={handleSubmit}
				>
					{loading ? (
						<div className="mx-auto mt-lg flex w-full flex-col items-center">
							<Spinner />
							<p className="mt-2 text-xxxsm text-primary-500">
								Veuillez patienter...
							</p>
						</div>
					) : (
						<>
							<div className="rounded-md bg-white p-sm shadow-md">
								<p className="font-bold">Votre profil</p>
								<div className="ml-0 mt-lg w-full">
									<Input
										errorMessage={errors.email as string}
										label="Email"
										onChange={handleChange}
										value={values.email}
										name="email"
										type="email"
										placeholder="Votre adresse e-mail professionnelle"
									/>
									{accountExists ? (
										<p className="pt-xxsm text-error-dark">
											Cette adresse email est déjà associée à un compte
											existant.{" "}
											<button
												type="button"
												onClick={(e) => {
													e.preventDefault();
													navigate(`/company-login?${school?.subdomain || ""}`);
												}}
												className="underline"
											>
												Cliquez ici pour vous connecter.
											</button>
										</p>
									) : null}
									<div className="mt-sm flex justify-between">
										<div className="w-1/2 pr-sm">
											<Input
												label="Prénom"
												errorMessage={errors.firstname as string}
												onChange={handleChange}
												value={values.firstname}
												name="firstname"
												type="text"
												placeholder="Votre prénom"
												className="w-full"
											/>
										</div>
										<div className="w-1/2 pl-sm">
											<Input
												label="Nom"
												errorMessage={errors.lastname as string}
												className="w-full"
												onChange={handleChange}
												value={values.lastname}
												name="lastname"
												type="text"
												placeholder="Votre nom"
											/>
										</div>
									</div>
									<InputPhoneNumber
										onChange={(value) => {
											setFieldValue("phone", value);
											// values.phone = e;
										}}
										value={values.phone}
										onBlur={(value) => {
											setFieldValue("phone", value);
										}}
										className="mt-sm"
										label="Téléphone"
									/>
									<div className="mt-sm">
										<p className="font-bold">
											Validation Conditions Générales d'Utilisation (CGU) et
											RGPD
										</p>
										<div className="mt-xsm flex items-center gap-xsm">
											<Switch
												onEnabled={() => {
													setFieldValue("cgu", true);
												}}
												onDisabled={() => {
													setFieldValue("cgu", false);
												}}
												checked={values.cgu}
											/>
											<span>
												J'ai lu et j'accepte{" "}
												<NavLink
													to="https://mentorgoal.com/cgu"
													target="_blank"
													className="font-bold"
												>
													les conditions générales d'utilisation (CGU)
												</NavLink>
												.
											</span>
										</div>
										<div className="mt-xsm flex items-center gap-xsm">
											<Switch
												onEnabled={() => {
													setFieldValue("rgpd", true);
												}}
												onDisabled={() => {
													setFieldValue("rgpd", false);
												}}
												checked={values.rgpd}
											/>
											<span>
												J'ai lu et j'accepte{" "}
												<NavLink
													to="https://mentorgoal.com/rgpd"
													target="_blank"
													className="font-bold"
												>
													la Politique de Confidentialité (RGPD)
												</NavLink>
												.
											</span>
										</div>
									</div>
								</div>
							</div>
							<ButtonPrimary
								onClick={undefined}
								id="register"
								name="submit"
								disabled={
									!values.email ||
									!values.firstname ||
									!values.lastname ||
									!values.phone ||
									!values.cgu ||
									!values.rgpd
								}
								className="mx-auto mt-md"
								type="submit"
								label="Suivant"
							/>
						</>
					)}
				</form>
			)}
		</Formik>
	);
}
