import type {
	EntEmployeeStatusType,
	EntEmployeeType,
} from "@/types/EntCompanyTypes";
import { Icon } from "@iconify/react";
import React, { useState } from "react";

import { checkmarkOutline, closeFill } from "@assets/Icons";

import { ENT_USER_STATUS } from "@constants/Users";

import { Badge } from "@designSystem/atoms/Badge";
import { Table } from "@designSystem/templates/table/Table";
import { success } from "@tools/Toasts";

import { editStatus } from "../../core/api/users.api";

export function CompanyUsersList({
	userList,
	loading,
}: {
	userList: EntEmployeeType[];
	loading: boolean;
}) {
	const [filters, setFilters] = useState(true);
	const changeUserStatus = async () => {
		try {
			await editStatus();
			success("Le statut de l'utilisateur a été modifié.");
		} catch {
			Error("Une erreur est survenue");
		}
	};

	const headers = [
		{
			label: "NOM",
		},
		{
			label: "EMAIL",
		},
		{
			label: "STATUT",
		},
		{
			label: "VALIDER L'ACCES",
		},
	];

	const getIconClasses = (status: EntEmployeeStatusType) => {
		switch (status) {
			case "active":
				return {
					close: "border-primary-200",
					open: "bg-primary-700P border-primary-700P text-white",
				};
			case "refused":
				return {
					close: "bg-primary-700P border-primary-700P text-white",
					open: "border-primary-200",
				};
			default: {
				return {
					close: "border-primary-700P text-primary-700P",
					open: "border-primary-700P text-primary-700P",
				};
			}
		}
	};
	const getStatus = (status: EntEmployeeStatusType) => {
		const statusObject = ENT_USER_STATUS.find((e) => e.type === status);
		return <Badge label={statusObject!.label} />;
	};

	const cellsResolver = (user: EntEmployeeType) => {
		return [
			{
				children: (
					<span>
						{user.firstname} {user.lastname}
					</span>
				),
			},
			{
				children: <span>{user.email}</span>,
			},
			{
				children: getStatus(user.status as EntEmployeeStatusType),
			},
			{
				children: (
					<div className="flex h-[24px] gap-sm">
						<div
							className="aspect-square h-full cursor-pointer"
							onClick={(event) => {
								event.stopPropagation();
								event.preventDefault();
								user.status = "active";
								changeUserStatus();
							}}
						>
							<Icon
								icon={checkmarkOutline}
								className={`h-full w-full rounded-sm border-[1px] ${
									getIconClasses(user.status as EntEmployeeStatusType).close
								}`}
							/>
						</div>
						<div
							className="aspect-square h-full cursor-pointer"
							onClick={(event) => {
								event.stopPropagation();
								event.preventDefault();
								user.status = "refused";
								changeUserStatus();
							}}
						>
							<Icon
								icon={closeFill}
								className={`h-full w-full rounded-sm border-[1px] ${
									getIconClasses(user.status as EntEmployeeStatusType).open
								}`}
							/>
						</div>
					</div>
				),
			},
		];
	};

	return (
		<div className="flex flex-col gap-md">
			<Table
				filtersProps={{ filters, setFilters }}
				className="mt-md"
				emptyMessage={"Aucun utilisateur"}
				isLoading={loading}
				headers={headers}
				rows={userList.map((user: EntEmployeeType) => {
					return {
						url: `/students/${user.id.split("/")[3]}`,
						cells: cellsResolver(user),
					};
				})}
			/>
		</div>
	);
}
