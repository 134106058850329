import { WhiteCard } from "@designSystem/molecules/WhiteCard";

import React from "react";

export function NoSchool() {
	return (
		<>
			<WhiteCard
				className="flex w-full flex-col mt-sm h-full justify-center items-center"
				title="Page introuvable"
				content={
					<div>
						<p>
							Il se peut que la page que vous recherchez n'existe plus. Vérifiez
							l'URL ou retournez à la page d'accueil.
						</p>
					</div>
				}
			/>
		</>
	);
}
