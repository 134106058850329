import type { ReducersMapObject } from "@reduxjs/toolkit";

import offersFiltersSlice from "@modules/offersFilters/offersFiltersSlice";
import usersFiltersSlice from "@modules/usersFilters/core/store/usersFilters.slice";

import { companySliceReducer } from "@/containers/company/core/store/company.slice";
import { companiesManagemenReducer } from "@containers/school/Companies/core/store/companiesManagement.slice";
import mailBoxSlice from "@containers/school/Mailbox/controller/mailBox.slice";
import { offersManagementReducer } from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import { referentsReducer } from "@containers/school/Referents/core/store/referents.slice";
import studentsManagementSlice from "@containers/school/Students/StudentsManagement/studentsManagementSlice";
import { eventsReducer } from "@containers/shared/Events/core/store/events.slice";
import betaJobboardSlice from "@containers/student/BetaJobboard/core/store/betaJobboard.slice";
import jobboardSlice from "@containers/student/Jobboard/core/store/jobboard.slice";
import OfferDetailSlice from "@containers/student/Jobboard/core/store/offerDetail.slice";
import { filtersReducer } from "@modules/filters/core/store/filters.slice";

export const REDUX_SLICES: ReducersMapObject = {
	filters: filtersReducer,
	events: eventsReducer,
	usersFilters: usersFiltersSlice,
	studentsManagement: studentsManagementSlice,
	offers: offersManagementReducer,
	company: companySliceReducer,
	mailBox: mailBoxSlice,
	offersFilters: offersFiltersSlice,
	companiesManagement: companiesManagemenReducer,
	jobboard: jobboardSlice,
	betaJobboard: betaJobboardSlice,
	offerDetail: OfferDetailSlice,
	referents: referentsReducer,
};
