import type { EntEmployeeStatusType } from "@/types/EntCompanyTypes";
import api from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
import axios from "axios";
import type { FormikValues } from "formik";

export async function sendFile(formData: FormData) {
	let rep = {};

	await api
		.post("media_objects", formData)
		.then(({ data }) => {
			rep = data;
		})
		.catch((err) => {
			rep = err;
		});
	return rep;
}

export async function checkIfEntExists(siret: string) {
	try {
		const { data } = await api.get(`check_if_siret_exists/${siret}`);
		return data.companies;
	} catch (e) {
		Error("Une erreur est survenue");
		throw new Error(e);
	}
}
export async function joinCompany(
	userId: string,
	companyId?: number,
	status: EntEmployeeStatusType = "askAccess",
) {
	// let rep;
	const updateEmployee = {
		id: userId,
		company: `/company/ent_companies/${companyId}`,
		status,
	};
	const queryEmployee = {
		query: `mutation {
updateEntEmployee(input: ${stringifyRequestInputs(updateEmployee)}) 
	{
		entEmployee {
			id
		}
	}
}`,
	};
	try {
		const rep = await api.post("graphql", queryEmployee);
		return rep;
	} catch (e) {
		throw new Error(e);
	}
}

export async function createEntEmployee(inputs: FormikValues) {
	try {
		const {
			data,
		}: {
			data: {
				code: number;
				message: string;
				entEmployeeId: number;
				coreUserId?: number;
				token?: string;
				originSchoolId?: number;
			};
		} = await axios
			.create({
				baseURL: `${import.meta.env.VITE_BACK}/`,
				timeout: 20000,
			})
			.post("user/create_user_ent", inputs);
		return data;
	} catch (e) {
		throw e.response.data;
	}
}

export async function createCompany(inputs: FormikValues, userId: string) {
	const existingCompanies = await checkIfEntExists(inputs.siret);
	if (existingCompanies.length > 0) {
		return { data: { alreadyExists: true } };
	}
	const data = {
		query: `mutation {
      createEntCompany (input: ${stringifyRequestInputs(inputs)}) {
        entCompany {
          id
        }
      }
    }`,
	};
	try {
		const rep = await api.post("graphql", data);
		const updateEmployee = {
			id: userId,
			company: rep.data.data.createEntCompany.entCompany.id,
		};
		const queryEmployee = {
			query: `mutation {
		        updateEntEmployee(input: ${stringifyRequestInputs(updateEmployee)}) {
		            entEmployee {
		              id
		            }
		        }
		    }`,
		};
		await api.post("graphql", queryEmployee);
		return rep;
	} catch (e) {
		throw new Error(e);
	}
}
