import { archiveOutline, checkmarkFill, closeFill } from "@assets/Icons";
import PropTypes from "prop-types";
import React from "react";

import "@assets/sass/ApplicationItem.scss";

import { getColumnIndex } from "@tools/Applications";

import { Avatar } from "@designSystem/atoms/Avatar";
import { Badge } from "@designSystem/atoms/Badge";
import { ProgressIndicator } from "@designSystem/atoms/Progress";

import { formatDate } from "@/tools/Dates";
import { CompanyName } from "./company/CompanyName";

export function ApplicationItem({
	onClick,
	application,
	companyName,
	className,
	type,
}) {
	const getFormatedActionDate = (app) => {
		switch (app.step) {
			case "interview":
				return `Entretien obtenu le ${formatDate(
					app.interviewDate || app.createdAt,
				)}`;
			case "relaunch":
				return `Relancée le ${formatDate(app.relaunchDate || app.createdAt)}`;
			case "sent":
				return `Envoyée le ${formatDate(app.sentDate || app.createdAt)}`;
			default:
				return `Crée le ${formatDate(app.createdAt)}`;
		}
	};
	return (
		<button
			className={`flex flex-col mt-4 gap-2 w-full ${className} ${
				onClick && "application"
			}`}
			onClick={onClick}
			type="button"
			key={application.id}
		>
			<div className="flex gap-2 justify-start w-full items-center">
				{application.archived && (
					<Badge
						label="Candidature archivée"
						className="no-pointer"
						leftIcon={archiveOutline}
						bgColor="bg-alert-info-turquoise-100"
						textColor="text-alert-info-turquoise-500"
						borderColor="border-alert-info-turquoise-500"
						hasBorder
					/>
				)}
				{application.accepted === true && (
					<Badge
						label="Embauché(e)"
						className="no-pointer"
						leftIcon={checkmarkFill}
						bgColor="bg-success-light"
						hasBorder
					/>
				)}
				{application.accepted === false && application.accepted !== null && (
					<Badge
						label="Candidature refusée"
						className="no-pointer"
						leftIcon={closeFill}
						bgColor="bg-primay-100"
						textColor="text-primary-500"
						borderColor="border-primary-500"
						hasBorder
					/>
				)}
			</div>
			<div className="flex items-center gap-sm w-full">
				<Avatar
					image=""
					modify={false}
					firstname={companyName?.split(" ")[0]}
					lastname={companyName?.split(" ")[1]}
					onChange={(e) => {
						setFieldValue("logo", e);
					}}
					size="sm"
					className="hidden md:flex"
				/>
				<div className="w-full">
					<div className="flex w-full items-center justify-between gap-sm">
						<div className="w-full text-left">
							<p className="text-xxxsm font-bold text-primary-300 wg-no-translate">
								{getFormatedActionDate(application)}
							</p>
							<div className="flex w-full items-center justify-between">
								<p className="wg-no-translate max-w-11/12 truncate text-left text-xsm">
									{type === "offer"
										? `${application.spsStudent.firstname} ${application.spsStudent.lastname}`
										: application.job}{" "}
									-{" "}
									{type === "offer" ? (
										application.job
									) : (
										<CompanyName
											nameToDisplay={companyName}
											nameColor="text-primary-300"
										/>
									)}
								</p>
								<div className="flex gap-xsm items-center">
									{application.offer && (
										<Badge
											label={`${
												(application.offer?.type === "direct" &&
													"Offre direct") ||
												(application.offer?.type === "preselection" &&
													"Offre présélection") ||
												application.offer.type
											}`}
											bgColor={`${
												(application.offer?.type === "direct" &&
													"bg-sunflower-light") ||
												(application.offer?.type === "preselection" &&
													"bg-emerald-light") ||
												"bg-stone-light"
											}`}
										/>
									)}
									{application.state === "refused" && (
										<Badge label="Non retenu" bgColor="bg-error-light" />
									)}
									{application.state === "accepted" && (
										<Badge label="Retenu" bgColor="bg-success-light" />
									)}
								</div>
							</div>
						</div>
					</div>

					<ProgressIndicator
						className="application-progress mt-xsm flex items-center"
						indexActive={getColumnIndex(application.step)}
						steps={[
							{
								label: "Préparation",
								date:
									application.createdAt && formatDate(application.createdAt),
								accentColor: 1,
								value: "readyToApply",
							},
							{
								label: "Envoyée",
								date: application.sentDate && formatDate(application.sentDate),
								accentColor: 2,
								value: "sent",
							},
							{
								label: "Relancée",
								date:
									application.relaunchDate &&
									formatDate(application.relaunchDate),
								accentColor: 3,
								value: "relaunch",
							},
							{
								label: "Entretien",
								date:
									application.interviewDate &&
									formatDate(application.interviewDate),
								accentColor: 4,
								value: "interview",
							},
						]}
					/>
				</div>
			</div>
		</button>
	);
}

ApplicationItem.propTypes = {
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	application: PropTypes.shape({
		id: PropTypes.string,
		job: PropTypes.string,
		accepted: PropTypes.bool,
		createdAt: PropTypes.string,
		sentDate: PropTypes.string,
		relaunchDate: PropTypes.string,
		interviewDate: PropTypes.string,
		step: PropTypes.string,
		organization: PropTypes.shape({
			name: PropTypes.string,
			logo: PropTypes.string,
		}),
	}).isRequired,
};

ApplicationItem.defaultProps = {
	className: "",
};
