import { useAppSelector } from "@config/redux/hook";
import React, { useContext } from "react";

import type { CompanySliceType } from "@/containers/company/core/store/company.slice";
import { logOutFill } from "@assets/Icons";
import type { AppState } from "@config/redux/store";
import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { Dropdown } from "@designSystem/molecules/Dropdown";
import { GlobalContext } from "@navigation/Router";
import { getCompanySubdomain, logout } from "@tools/Auth";
import { deleteCookie } from "@tools/Cookies";
import { SwitchSchoolModal } from "../../ui/modal/SwitchSchool.modal";

export function CompanyLayoutHeader() {
	const { school, title } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);
	const { user } = useContext(GlobalContext);
	function resolveMenuItems() {
		const dropdownItems = [
			{
				label: "Déconnexion ",
				action: () => {
					logout(
						user?.schoolName !== undefined &&
							(user?.schoolName.toLowerCase() === "amos" ||
								user?.schoolName.toLowerCase() === "cmh" ||
								user?.schoolName.toLowerCase() === "esdac") &&
							(user.roles.includes("ROLE_SCHOOL") ||
								user.roles.includes("ROLE_CAMPUS_ADMIN") ||
								user.roles.includes("ROLE_CAMPUS_USER")),
						`/company-login?${getCompanySubdomain()}`,
					);
				},
			},
		];

		if (user?.profilesCount && user.profilesCount > 1) {
			dropdownItems.unshift({
				label: "Changer de profil",
				action: () => {
					deleteCookie("interface");
					deleteCookie("token");
					window.location.replace(`/choose_profile?${getCompanySubdomain()}`);
				},
			});
		}
		return dropdownItems;
	}

	return (
		<header className={"relative w-full"}>
			{!location.pathname.includes("login") &&
				!location.pathname.includes("register") && (
					<div className="flex justify-between mx-auto w-11/12 mt-lg">
						<p className="font-bold text-md">
							Espace entreprise{school?.name && ` ${school.name}`}
						</p>
						{!!user && (
							<Dropdown
								direction="left"
								button={<ButtonTertiary icon={logOutFill} />}
								items={resolveMenuItems()}
							/>
						)}
					</div>
				)}
			<div className="rounded-circle w-full bg-white shadow-sm flex px-md py-sm mt-sm">
				{school && (
					<Avatar
						size="lg"
						className="mr-sm my-auto"
						firstname={school?.name as string}
						bgColor={school?.color ? (school.color as string) : ""}
						image={
							school.logo
								? `${import.meta.env.VITE_S3}${school.logo.filePath}`
								: null
						}
					/>
				)}

				<div className="my-auto">
					{typeof title === "string" ? (
						<span className="font-bold text-lg">{title}</span>
					) : (
						<>
							<p className="font-bold text-lg mb-sm">{title?.title}</p>
							<p className="font-bold">{title?.subtitle}</p>
						</>
					)}
				</div>
			</div>
			<SwitchSchoolModal show={false} onClose={() => {}} />
		</header>
	);
}
