import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { NoteType } from "@/types/notesType";
import type { UserType } from "@/types/userTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import type { structureViewType } from "@containers/school/CVBoard";
import api, {
	checkGraphQLError,
	type StandardGraphQLResponse,
} from "@tools/API";
import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";
export async function editUser(inputs: Pick<UserType, "id"> & { cv?: string }) {
	let rep = {};
	const data = {
		query: `mutation {
          updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
            user: spsStudent {
              id
            }
          }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function getAnswer(applicationId: string) {
	let rep = {};
	const data = {
		query: `
      query {
        application(id: "${applicationId}") {
            id
            answerPreparationQuestions
        }
      }
      `,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.application;
	});
	return rep;
}

export async function deleteUser(uri: string) {
	const rep = {};
	const data = {
		query: `mutation{
        deleteSpsStudent(input: {id:"${uri}"}){
          user: spsStudent {
            id
          }
        }
      }`,
	};
	await api.post("graphql", data);
	return rep;
}

export async function sendFile(formdata: FormData) {
	let rep = {};

	await api
		.post("media_objects", formdata)
		.then(({ data }: { data: MediaObjectType }) => {
			rep = data;
		});
	return rep;
}

export async function getStudent(id: string) {
	let rep: { user: UserType } = { user: {} as UserType };

	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        id
        firstname
        lastname
        address
        phone
        status
        email
        createdAt
        birthdate
        mobility
        city
        dateOfInvitation
        portfolio
        linkedin
        cvRelaunchDate
        customLink1
        customLink2
        applicationsCount
        relaunchsCount
        interviewsCount
        engNotes (itemsPerPage: 1000, order: [{datetime: "DESC"}]) {
          collection {
            id
            content
            datetime
          }
        }
        contractSearched
        desiredStartOfWork
        desiredJob
        geoAreaOfResearch
        gender
        campus: coreCampus {
          isGalliaEnabled
        }
        tags
        avatar {
          id
          filePath
          createdAt
          size
        }
        cv {
          filePath
          state
          updatedAt
          id
          createdAt
          size
        }
        motivationLetter {
          filePath
          createdAt
          size
        }
        portfolioPdf {
          filePath
          createdAt 
          size
        }
        school: coreSchool {
          id
          name
        }
        spsPromotion  {
          id
          name
        }
      }
    }`,
	};
	await api
		.post("graphql", data)
		.then(({ data }: { data: { data: { user: UserType } } }) => {
			rep = data.data;
		});
	const mediaCollection: MediaObjectType[] = await getStudentSchoolFiles(id);
	rep.user.schoolDocuments = mediaCollection;
	return rep;
}
export async function getStudentSchoolFiles(id: string) {
	const data = {
		query: `
      query {
        mediaObjects(spsStudent: "${id}", type: "school", order: [{createdAt: "DESC"}]) {
          collection {
            filePath
            id
            createdAt
            size
          }
        }
      }
    `,
	};
	const rep: StandardGraphQLResponse = await api.post("graphql", data);
	return rep.data.data.mediaObjects.collection;
}

export async function deleteSchoolDocument(id: string) {
	const data = {
		query: `mutation {
        deleteMediaObject(input: {id:"${id}"}) {
          mediaObject {
            id
          }
        },
      }`,
	};
	let rep;
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function getNeighbors(
	id: number,
	structure: structureViewType,
	filters: FiltersType,
) {
	try {
		const data = await api.post("get_user_neighbors", {
			id,
			...structure,
			...filters,
		});
		return data.data;
	} catch (error) {
		throw new Error(error);
	}
}

export async function getSideData(id: string) {
	let rep = {};
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        professionalExperiences: spsProfessionalExperiences {
          collection {
            id
            job
            organization : organizationV2
            contract
            startDate
            endDate
            detail
          }
        }
        formations: spsFormations {
          collection {
            id
            name
            schoolName
            startDate
            endDate
            city
            detail
          } 
        }
        projects: spsProjects {
          collection {
            id
            name
            detail
            url
          }
        }
        applications: spsApplications (itemsPerPage: 10000, order: [{id: "DESC"}]){
          collection {
            id
            offer{
              crmCompany{
                name
              }
              type
            }
            organizationV2
            job
            step
            location
            interviewDate
            interviewEvaluation
            notes
            contract
            type
            createdAt
            sentDate
            accepted
            archived
            state
            relaunchDate
            interviewDate
          }
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getApplication(id: string) {
	let rep = {};
	const queryData = {
		query: `query {
      application (id:"${id}"){
        id
        contract
        offer {
          type
          crmCompany{
            name
            logo
            website
            contactPhone
            contactEmail
            address
          }
        }
        documents {
          collection {
            filePath
            size
            createdAt
          }
        }
        actions {
          collection {
            id
            done
            title
            note
            datetime
            type
          }
        }
        interviewLocation
        explanationEvaluation
        answerPreparationQuestions
        contacts{
          collection{
            id 
            firstname
            lastname
            job
            phone
            email
            linkedin
            avatar{
              id
              filePath
            }
          }
        }
      }
    }`,
	};
	const { data } = await api.post("graphql", queryData);

	if (data) {
		rep = data.data?.application;
	}

	return rep;
}
export async function relaunchCVStudent(id: number) {
	let rep = {};

	await api.post(`relaunch_cv_mail/${id}`).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function getSchoolPromotions(structure: object) {
	let rep = {};

	const data = {
		query: `
    query {
      promotions(${stringifyRequestFilters(structure)}, archived: false, type: "student", itemsPerPage: 1000) {
        collection{
          id
          name
          archived
          toPlace
          notSearching
          placed
          surrend
          applications
          relaunchs
          interviews
          year
          school{
            id
            name
          }
          campus {
            isGalliaEnabled
          }
        }
        paginationInfo {
          totalCount
          lastPage
          itemsPerPage
        }
      }
    }
`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.promotions;
	});
	return rep;
}

export async function getSchoolTag(school: string) {
	let rep = {};

	const data = {
		query: `query{
            tags(school: "${school}", itemsPerPage: 1000){
                collection{
                  id
                  name
                  color
                  description
                  type
              }
              paginationInfo {
                itemsPerPage
                lastPage
                totalCount
              }
            }
            
          }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.tags;
	});
	return rep;
}

export async function updateStudentTag(inputs: Partial<UserType>) {
	try {
		const data = {
			query: `mutation{
        updateSpsStudent(input:${stringifyRequestInputs(inputs)}){
          user: spsStudent {
            id
            tags
          }
        }
      }`,
		};
		const response = await api.post("graphql", data);
		return checkGraphQLError(response, "Erreur lors de la modification");
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function editMedia(inputs: Partial<MediaObjectType>) {
	let rep = {};
	const data = {
		query: `mutation {
            updateMediaObject(input: ${stringifyRequestInputs(inputs)}) {
                mediaObject {
                    id
                    filePath
                    state
                }
            }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export const exportStudentsToGallia = async (ids: string[]) => {
	let rep = {};
	await api
		.post("export_to_gallia", ids)
		.then(({ data }) => {
			rep = data;
		})
		.catch((error) => {
			rep = error.response.data;
		});
	return rep;
};

export const getStudentGalliaStatus = async (id: number) => {
	let rep = {};
	await api
		.get(`is_user_synced_with_gallia/${id}`)
		.then(({ data }) => {
			rep = data;
		})
		.catch((error) => {
			rep = error.response.data;
		});
	return rep;
};

export const createEngNote = async (inputs: Partial<NoteType>) => {
	let rep = {};
	const data = {
		query: `mutation {
          createEngNote(input: ${stringifyRequestInputs(inputs)}) {
            engNote{
              id
            }
          }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.createEngNote;
	});
	return rep;
};

export const editEngNote = async (inputs: Partial<NoteType>) => {
	let rep = {};
	const data = {
		query: `mutation {
          updateEngNote(input: ${stringifyRequestInputs(inputs)}) {
            engNote{
              id
            }
          }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateEngNote;
	});
	return rep;
};

export const deleteEngNote = async (id: string) => {
	let rep = {};
	const data = {
		query: `mutation {
          deleteEngNote(input: {id:"${id}"}) {
            engNote{
              id
            }
          }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.deleteEngNote;
	});
	return rep;
};

export const fetchStudentNotes = async (studentId: string) => {
	let rep = {};
	const data = {
		query: `query {
      spsStudent(id: "${studentId}") {
        engNotes (itemsPerPage: 1000, order: [{datetime: "DESC"}]) {
          collection {
            id
            content
            datetime
          }
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.spsStudent.engNotes;
	});
	return rep;
};
