import type { EntOffersType } from "@/types/EntCompanyTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";

import {
	type CompanySliceType,
	setActiveOffer,
} from "@/containers/company/core/store/company.slice";
import { OfferSmallCard } from "@/containers/company/ui/offers/OfferSmallList/OfferSmallCard";
import { arrowBackOutline, arrowDownwardOutline } from "@assets/Icons";
import type { AppState } from "@config/redux/store";

type SmallListType = {
	offersToTreat?: EntOffersType[];
	offersRefused?: EntOffersType[];
	offersAccepted?: EntOffersType[];
	offersExpired?: EntOffersType[];
	offersPending?: EntOffersType[];
	onGoBack?: () => void;
};

export function OfferSmallList({
	offersToTreat = [],
	offersRefused = [],
	offersAccepted = [],
	offersPending = [],
	offersExpired = [],
	onGoBack,
}: SmallListType) {
	const dispatchEvent = useDispatch();
	const { entOffersLoading, activeOffer } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);

	const isActive = (id: string) => {
		return (activeOffer && id === activeOffer.id) || false;
	};

	const skeleton = () => {
		const skeletons = Array(3)
			.fill(["#e9f5e4", "#fdf0e4", null, "#fbe6e1"])
			.flat();
		return skeletons.map((color) => (
			<div key={color || "color"} className="w-full">
				<Skeleton baseColor={color} height={125} />
			</div>
		));
	};
	const offersSorted = [
		...offersToTreat,
		...offersAccepted,
		...offersRefused,
		...offersPending,
		...offersExpired,
	];
	const getNext = () => {
		const currentIndex = offersSorted.findIndex((elem: { id: string }) => {
			return elem.id === activeOffer?.id;
		});

		if (currentIndex + 1 !== offersSorted.length) {
			dispatchEvent(setActiveOffer(offersSorted[currentIndex + 1]));
		}
	};
	const getPrev = () => {
		const currentIndex = offersSorted.findIndex((elem: { id: string }) => {
			return elem.id === activeOffer?.id;
		});

		if (currentIndex !== 0) {
			dispatchEvent(setActiveOffer(offersSorted[currentIndex - 1]));
		}
	};
	const handleKeyPress = (event: KeyboardEvent) => {
		if (!entOffersLoading) {
			if (event.key === "ArrowDown") {
				event.preventDefault();
				getNext();
			}
			if (event.key === "ArrowUp") {
				event.preventDefault();
				getPrev();
			}
			if (event.key === "ArrowLeft") {
				if (onGoBack) {
					onGoBack();
				}
			}
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyPress);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [handleKeyPress]);

	const renderCategory = (offers: EntOffersType[], label: string) => {
		if (!offers.length) return;
		return (
			<div className="flex flex-col gap-4">
				<p className="text-sm	font-bold mt-sm">{label}</p>
				{offers.map((offer) => (
					<OfferSmallCard
						key={offer.id}
						offer={offer}
						isActive={isActive(offer.id)}
					/>
				))}
			</div>
		);
	};

	return (
		<>
			<div className="h-fit-content w-full gap-sm rounded-2xl bg-white p-sm">
				<div className="flex gap-xsm">
					{onGoBack && (
						<button
							type="button"
							className="mb-sm flex items-center"
							onClick={onGoBack}
						>
							<Icon
								icon={arrowBackOutline}
								className="inline rounded border-b-2 border-l border-r-2 border-t border-primary-900"
							/>
							<p className="ml-1 font-bold">Retour</p>
						</button>
					)}
					<button
						type="button"
						className="mb-sm flex items-center"
						onClick={() => getNext()}
					>
						<Icon
							icon={arrowDownwardOutline}
							className="inline rounded border-b-2 border-l border-r-2 border-t border-primary-900"
						/>
						<p className="ml-1 font-bold">Suivant</p>
					</button>
				</div>

				{entOffersLoading ? (
					<>{skeleton()}</>
				) : (
					<div>
						{renderCategory(offersToTreat || [], "À traiter")}
						{renderCategory(offersAccepted || [], "Acceptées")}
						{renderCategory(offersRefused || [], "Refusées")}
						{renderCategory(offersPending || [], "En attente")}
						{renderCategory(offersExpired || [], "Terminées")}
					</div>
				)}
			</div>
		</>
	);
}
