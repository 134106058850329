import type { EntOffersType } from "@/types/EntCompanyTypes";

import api from "@tools/API";

export async function queryLatestOffers(profileId: number) {
	const data = {
		query: `query {
              entOffers(entCompanySchoolProfile: "${profileId}", status_list:["active", "pending", "new"], order: [{createdAt: "DESC"}],itemsPerPage:2) {
                collection {
                  id
                  job
                  description
                  location
                  telework
                  qualification
                  status
                  link
                  contract
                  emailPreference
                  createdAt
                  spsSelection {
                    id
                    name
                    token
                    lastOpening
                    userLastAdded
                    selectionUsers {
											collection {
												id
											} 
                  	}
                  }
                }
              }
            }`,
	};
	try {
		const rep = await api.post("graphql", data);
		const collection: EntOffersType[] = rep.data.data.entOffers.collection;
		return collection;
	} catch (error) {
		throw new Error(error);
	}
}

import { getCookie } from "@tools/Cookies";
import axios from "axios";
export async function checkAccountStatus(email: string) {
	try {
		const {
			data,
		}: {
			data: {
				code: number;
				message: string;
				exists: boolean;
				employeeCount: number;
			};
		} = await axios
			.create({
				baseURL: `${import.meta.env.VITE_BACK}/`,
				timeout: 20000,
			})
			.get(`/check_user_exists/${email}`);
		return data;
	} catch (e) {
		throw new Error(e);
	}
}

export async function fetchCompanyProfiles(showWithoutCompanies = false) {
	const response = await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getCookie("mainToken")}`,
			},
		})
		.get(
			`/user/retrieve_profiles?companyLogin=1${showWithoutCompanies ? "&noCompanies=1" : ""}`,
		);

	return response.data.profiles;
}
