import { TooltipContainer } from "@/designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@/designSystem/atoms/TooltipElement";
import { arrowDownOutline, arrowUpOutline, searchFill } from "@assets/Icons";
import { SystemIDProvider } from "@config/core/system.id-provider";
import { Input } from "@designSystem/molecules/Input";
import { Icon } from "@iconify/react";
import React from "react";
import type { SelectPresenterProps } from "./SelectTypes";
import { useSelect } from "./useSelect";

type SelectProps = SelectPresenterProps;

/**
 * @description SelectV2 is a component that allows the user to select option from a list.
 * @param {string} name - The name of the select.
 * @param {Array<{label: string, value: string | number, icon?: IconifyIcon}>} options - The list of options.
 * @param {boolean} isRequired - If true, the select will be required.
 * @param {string | number} preselectedOptionValue - The value of the preselected option.
 * @param {function} onSelectOption - The function to call when an option is selected.
 * @param {boolean} hasSearchbar - If true, the multiselect will have a search bar.
 * @param {string} searchbarPlaceholder - The placeholder of the search bar.
 * @param {string} label - The label of the multiselect.
 * @param {string} tooltip - The tooltip of the multiselect.
 * @param {boolean} useFullWidth - If true, the multiselect will use the full width.
 * @returns {JSX.Element} - A MultiselectV2 component.
 */
export function SelectV2({
	name,
	label,
	onSelectOption,
	tooltip,
	isRequired,
	options,
	useFullWidth = false,
	hasSearchbar = false,
	searchbarPlaceholder,
	preselectedOptionValue,
}: SelectProps) {
	const presenter = useSelect({
		name,
		label,
		onSelectOption,
		tooltip,
		isRequired,
		options,
		useFullWidth,
		hasSearchbar,
		searchbarPlaceholder,
		preselectedOptionValue,
	});
	return (
		<div
			className={`relative select-none ${
				presenter.useFullWidth ? "w-full" : "w-52 max-w-52"
			}`}
			id={`select-${name}`}
			tabIndex={0}
			role="listbox"
			onBlur={presenter.handleOnBlur}
			ref={presenter.selectV2Ref}
		>
			<SelectV2Label presenter={presenter} />
			<SelectV2Box presenter={presenter} />
			<SelectV2Dropdown presenter={presenter} />
		</div>
	);
}

/**
 * @description SelectV2Label is a component that displays the label of the select.
 * @returns {JSX.Element} - A SelectV2Label component.
 */
function SelectV2Label({
	presenter,
}: { presenter: ReturnType<typeof useSelect> }) {
	const idProvider = new SystemIDProvider();
	const tooltipId = idProvider.generate();

	return (
		<div className={"flex gap-xsm mb-1"}>
			<label className="text-xxsm  text-primary-500">
				{presenter.label}
				<span className="text-alert-error-500 text-xxsm">
					{presenter.isRequired ? "*" : ""}
				</span>
			</label>
			{presenter.tooltip && (
				<>
					<TooltipElement id={`tooltip-select-${tooltipId}`} />
					<TooltipContainer
						anchorId={`#tooltip-select-${tooltipId}`}
						place="right"
					>
						{presenter.tooltip}
					</TooltipContainer>
				</>
			)}
		</div>
	);
}

/**
 * @description SelectV2Box is a component that displays the box of the select.
 * @returns {JSX.Element} - A SelectV2Box component.
 */
function SelectV2Box({
	presenter,
}: { presenter: ReturnType<typeof useSelect> }) {
	return (
		<div
			className={`bg-white border rounded-md flex py-xsm px-sm cursor-pointer justify-between w-full  ${
				presenter.isOpen
					? "border-accent-1-dark shadow-accent1Light"
					: "border-primary-150 hover:border-accent-1 hover:shadow-accent1Lighter"
			}`}
			onClick={() => {
				presenter.toggleIsOpen();
			}}
		>
			<div
				className={`flex gap-xsm items-center justify-start ${
					presenter.useFullWidth ? "w-full" : "w-48"
				}`}
			>
				{presenter.optionToShow.icon && (
					<div className="h-full flex justify-center items-center">
						<Icon
							icon={presenter.optionToShow.icon}
							className="text-base font-normal text-primary-700P min-w-5 min-h-5"
						/>
					</div>
				)}
				<div
					className={`text-base font-normal text-primary-700P truncate text-center flex justify-start items-center h-full${
						presenter.useFullWidth ? "w-full" : "max-w-36"
					}`}
				>
					<p>{presenter.optionToShow.label}</p>
				</div>
			</div>
			<div className="flex gap-xsm">
				<Icon
					className="h-6 text-primary-700P"
					icon={presenter.isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>
		</div>
	);
}

/**
 * @description SelectV2Dropdown is a component that displays the dropdown of the multiselect.
 * @returns {JSX.Element} - A SelectV2Dropdown component.
 */
function SelectV2Dropdown({
	presenter,
}: { presenter: ReturnType<typeof useSelect> }) {
	return (
		presenter.isOpen && (
			<div
				className={`absolute flex flex-col gap-xxsm mt-xxsm ${
					presenter.useFullWidth ? "w-full" : "max-w-[320px]"
				} z-50`}
			>
				{presenter.hasSearchbar && (
					<Input
						name=""
						onChange={presenter.handleOnSearch}
						icon={searchFill}
						placeholder={presenter.searchbarPlaceholder}
						value={presenter.search}
						useAutofocus={true}
					/>
				)}
				<SelectOptionsList presenter={presenter} />
			</div>
		)
	);
}

/**
 * @description MultiselectV2OptionsList is a component that displays the list of options of the multiselect.
 * @returns {JSX.Element} - A MultiselectV2OptionsList component.
 */
function SelectOptionsList({
	presenter,
}: { presenter: ReturnType<typeof useSelect> }) {
	const idProvider = new SystemIDProvider();

	return (
		<div
			className={
				"overflow-y-auto bg-white shadow-md rounded-[4px] p-xsm max-h-[280px]"
			}
			style={
				presenter.useFullWidth ? undefined : { width: presenter.listWidth }
			}
		>
			{presenter.options.map((option) => {
				return (
					<div
						className={`px-sm py-xsm transition-all cursor-pointer flex items-center gap-xsm
						hover:bg-primary-100
						`}
						key={`option-${idProvider.generate()}`}
						onClick={() => {
							presenter.selectThisOption(option);
						}}
					>
						{option?.icon && (
							<Icon
								className="min-h-4 min-w-4 text-primary-700P"
								icon={option.icon}
							/>
						)}
						<span
							className={
								presenter.isSelected(option)
									? "text-xsm font-bold"
									: "text-xsm font-normal"
							}
						>
							{option.label}
						</span>
					</div>
				);
			})}
		</div>
	);
}
